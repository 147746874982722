<template>
    <div class="top_content">
        <div class="leftcontent">
            <div class="logo">
                <img  src="../../../static/zyywhjxy.png" />
            </div>
        </div>

        <div class="wrpCls">
            <a-button type="link" @click="log()" block style="color: #121212;font-size: 0.68rem;">
                登录
            </a-button>

        </div>

       <!-- <ul class="ul_header">
            <li style="text-align: right;margin-right: 60px;">
                <img src="../../../static/logo.png"  />
                <a style="color: #000000;" href="#/newlogin">中医药文化进校园</a></li>
                <li>
                    <a href="#/orderserver" style="padding:3px 10px; border-radius: 5px;">
                    <img src="../../../static/dgkf.png" style="width: 30px;height: auto;"/>
                    订购&客服</a>
                </li>
           
        </ul>
        -->
        
    </div>
</template>

<script>
    export default {
        name: "companyheader",
        data(){
            return{

            }
        },
        mounted(){

        },
        methods:{
         
            logout(){

                removeItem("un");
                removeItem("Token");
                removeItem("mn");
                this.$router.push({ path: "/" });
            },
            log(){
              
                this.$router.push({ path: "/newlogin" });
            },
            search(){
                this.$emit("search",this.keywords);
            },
   
        }
    }
</script>

<style scoped lang="less">
    .logo{

        display: flex;
        cursor: pointer;
      
        justify-items: center;
        img{
            
        height: 1.09rem;
        vertical-align: middle;
        display: inline-block;
        }
   
    }


    .top_content{
        width: 100%;
        display: flex;
        height: 1.51rem;


        flex-direction: row;
        justify-content: space-between;
    }
    .leftcontent{
        display: flex;
        flex-direction: row;
        justify-items: center;

    }
    .wrpCls{
        height: 1.51rem;

        display: flex;
        justify-items: center;
    }
    .ul_header{
        width: 100%;
        list-style: none;
        display:flex;
        flex-direction: row;
        font-size: 22px;
        li{
            width: 50%;
            img{
                width: 40px;
                height: auto;;
            }
        }
    }
</style>
